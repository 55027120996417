<template>
  <div>
    <!-- Users list actions -->
    <div
      v-show="done && !showJson"
      class="input-group mb-3"
      :class="!isAllGames(game) ? 'moveup' : ''"
    >
      <DadminGames :game="game" :sub="sub" @game="setGame" />
      <div class="form-control btn"></div>
      <!-- Json Edit users saves Dropdown menu -->
      <button
        class="btn btn-outline-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-filetype-json"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li v-for="(inv, index) in inventoryTypes" :key="index">
          <a class="dropdown-item" @click="inventory(inv)">{{ inv }}</a>
        </li>
      </ul>
      <!-- Delete users saves Button -->
      <button
        class="btn btn-outline-secondary"
        type="button"
        @click="!confirm ? (confirm = 'remove') : (confirm = '')"
      >
        <i class="bi bi-trash"></i>
      </button>
    </div>

    <!-- Edit json -->
    <div v-if="showJson">
      <div v-if="!edit.form" class="input-group">
        <div class="form-control label">
          Edit inventory in game {{ gameInventory }}
        </div>
        <button
          type="button"
          class="input-group-text btn btn-outline-secondary"
          aria-label="Close"
          @click="doShow"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <EditJson :edit="edit" @save="doSave" @cancel="doShow" />
    </div>

    <!-- Confirm actions -->
    <ActionConfirm
      :action="actions[confirm]"
      @confirm="doConfirm"
      @cancel="doCancelConfirm"
    />
  </div>
</template>

<script>
import ActionConfirm from "./ActionConfirm.vue";
import DadminGames from "./DadminGames.vue";
import EditJson from "./EditJson.vue";

const cmdInventory = "user.saves.get/";
const cmdInventoryDel = "user.saves.remove/";

export default {
  name: "UsersListActions",
  components: {
    ActionConfirm,
    DadminGames,
    EditJson,
  },

  props: {
    game: String,
    sub: String,
  },

  data: function () {
    return {
      /** in all games mode - if done is true than user has inventory saved in games */
      done: false,

      /** confirm action */
      confirm: "",
      actions: {
        remove: {
          name: "remove",
          text: "Remove users saves from the game?",
          word: "remove",
        },
      },

      /** Inventory types */
      inventoryTypes: [
        "All",
        "PlayerCharacter.itemsContainer",
        "PlayerCharacter.armorComponent",
        "PlayerState.hubStorageComponent",
      ],

      /** game name to get and show inventory json */
      gameInventory: "",

      /** json edit object used in EditJson component  */
      edit: {
        name: "name",
        json: {},
        form: true, // show form or json in EditJson if true
        formStart: true, // start show form when opened if true
        formTitle: "Items", // title of the form
        formFilterNames: [
          // Skip next fields in form
          "_ClassName",
          "saveStoryKeyManager",
          "mosaicStates",
          "questProcessorComp",
          "PlayerController",
          "reputationComponent",
          "LevelsSave",
        ],
      },

      /** show EditJson flag */
      showJson: false,
    };
  },

  mounted() {
    let that = this;

    // Set game in not in all games mode
    if (!this.isAllGames(this.game)) {
      this.setGame(this.game);
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }

      // Process answer to cmdInventory command
      switch (gw.command) {
        case cmdInventory + that.gameInventory + "/" + that.sub: {
          that.loadInventory(data);
          break;
        }
      }
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /**
     * Sets the game name for retrieving and showing inventory JSON.
     *
     * @param {string} game - The name of the game.
     */
    setGame(game) {
      // Log the game parameter for debugging purposes.

      // Indicate that the inventory is existed.
      this.done = true;

      // Set the game name for retrieving and showing inventory JSON.
      this.gameInventory = game;
    },

    /**
     * Requests inventory JSON from server.
     */
    inventory(inv) {
      // Request JSON from server
      this.sendCmdInventory(this.gameInventory, this.sub);
      this.inventorySelected = inv;
    },

    /**
     * Save the JSON view to the server.
     *
     * This function is called when the user clicks the "Save" button in the
     * JSON view. It sets the `showJson` property of the component to `false`,
     * which hides the JSON view.
     */
    doSave() {
      // Log the function name for debugging purposes.
      console.debug("doSave");

      // Hide the JSON view by setting the `showJson` property to `false`.
      this.showJson = false;
    },

    /**
     * Toggles the JSON view.
     *
     * This function is called when the user clicks Cansel or Close buttons in
     * the JSON view. It toggles the `showJson` property of the component.
     */
    doShow() {
      // Log the function name for debugging purposes.
      console.debug("doShow");

      // Toggle the JSON view by setting the `showJson` property to its inverse.
      this.showJson = !this.showJson;
      this.doCancelConfirm();
    },

    doConfirm(action) {
      switch (action) {
        case "remove": {
          this.sendCmdInventoryDel(this.gameInventory, this.sub);
          break;
        }
      }
      this.doCancelConfirm();
    },

    doCancelConfirm() {
      this.confirm = "";
    },

    /**
     * Sends a command to retrieve the inventory of a specified game for a given
     * user.
     *
     * @param {string} game - The name of the game.
     * @param {string} sub - The user's sub identifier.
     */
    sendCmdInventory(game, sub) {
      let cmd = cmdInventory + game + "/" + sub;
      this.teoweb.sendCmd(cmd);
    },

    sendCmdInventoryDel(game, sub) {
      let cmd = cmdInventoryDel + game + "/" + sub;
      this.teoweb.sendCmd(cmd);
    },

    /**
     * Loads the inventory JSON from the server's response into the edit panel.
     *
     * @param {string} data - The JSON string from the server's response.
     */
    loadInventory(data) {
      // Decompress data
      data = window.decompressData(data);

      // Parse the JSON string into an answer object.
      let answer = JSON.parse(data);

      // Log the inventory JSON for debugging purposes.
      console.debug("got user.saves.get answer:", answer);
      console.debug("selected inventory:", this.inventorySelected);

      let obj = {};

      // Filter answer json depend of inventorySelected dropdown menu
      if (this.inventorySelected == "All") {
        obj = answer;
      } else {
        let parts = this.inventorySelected.split(".");
        if (parts[1] == "armorComponent") {
          obj = answer.MapData[parts[0]][parts[1]];
        } else {
          obj = answer.MapData[parts[0]][parts[1]]["itemStacks"];
        }
      }

      // Set the inventory JSON into the edit panel.
      // this.edit.json = answer.MapData.PlayerCharacter.itemsContainer.itemStacks;
      this.edit.json = obj;
      this.edit.formTitle = this.inventorySelected;

      // Show the edit panel.
      this.showJson = true;
    },

    isAllGames(game) {
      return !(game && game != "All games");
    },
  },
};
</script>

<style scoped>
.moveup {
  margin-top: -50px;
}
</style>
