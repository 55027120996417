<template>
  <span>
    {{ users.lenght }}
  </span>
</template>

<script>
export default {
  name: "UsersLength",

  data() {
    return {
      users: {
        lenght: 0, // Number of users
      },
    };
  },

  mounted() {
    let that = this;
    const cmdLenght = "users.length";

    // Send cmdLenght command with parameter name to WebRTC server
    this.sendCmd(cmdLenght).then(
      // Process success answer
      (data) => (that.users.lenght = JSON.parse(data)),
      // Process an error
      (err) => console.error("cmdLenght error:", err)
    );
  },
};
</script>
