<!-- List of server commands. -->
<template>
  <h1>Commands</h1>
  Number of commands: {{ commands.length }}<br />

  <!-- Filter -->
  <div class="input-group mt-3 mb-4">
    <!-- Filter text -->
    <input
      class="form-control"
      v-model="commandsFilter.filter"
      placeholder="Enter filter text"
    />

    <!-- Clear filter button -->
    <button
      type="button"
      class="btn bg-transparent"
      @click="clearFilter()"
      style="
        opacity: 0.5;
        margin-left: -33px;
        padding-top: 1px;
        margin-top: 5px;
        margin-bottom: 5px;
        z-index: 100;
      "
    >
      <i class="fa fa-times"></i>x
    </button>

    <!-- Filter type button -->
    <button type="button" class="btn btn-primary" @click="changefilterOnline()">
      {{ commandsFilter.filterTypeBtnName }}
    </button>
  </div>

  <!-- List of commands -->
  <div v-for="(cmd, index) in commands" :key="index">
    <div v-if="checkFilters(cmd)">
      <h4 class="text-success">{{ cmd.command }}</h4>
      <div class="descr">{{ cmd.descr }}</div>
      <div v-if="cmd.params">
        <span class="params">params:</span> {{ cmd.params }}
      </div>
      <div v-if="cmd.return">
        <span class="return">return:</span> {{ cmd.return }}
      </div>
      <div><span class="processIn">process in:</span> {{ cmd.processIn }}</div>
      <div class="mt-2 mb-0" v-if="cmd.request">
        <span class="request">request example:</span>
        <CodeCopy :text="cmd.request" />
      </div>
      <div class="mt-2 mb-2" v-if="cmd.response">
        <span class="response">response example:</span>
        <CodeCopy :text="cmd.response" />
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import CodeCopy from "./CodeCopy.vue";
const cmdCommandsJson = "commjson";

export default {
  name: "PageCommands",

  components: { CodeCopy },

  data() {
    return {
      commands: [],
      commandsTypes: ["All"],
      commandsFilter: {
        filter: "",
        filterTypeBtnName: "All",
      },
    };
  },

  mounted() {
    let that = this;

    // Send 'commands' command to WebRTC server
    this.sendCmd(cmdCommandsJson).then(
      // Process success answer
      (data) => {
        that.commands = JSON.parse(data);
        that.collectProcessIn();
      },
      // Process an error
      (err) => console.error("cmdLenght error:", err)
    );
  },

  methods: {
    /**
     * Change state filter online value and buttons name
     */
    changefilterOnline: function () {
      // Get index of filterTypeBtnName in commandsTypes
      const index = this.commandsTypes.indexOf(
        this.commandsFilter.filterTypeBtnName
      );
      // If index is -1 or it is the last item in array, go back to the first item
      if (index == -1 || index == this.commandsTypes.length - 1) {
        this.commandsFilter.filterTypeBtnName = this.commandsTypes[0];
        return;
      }
      // Change filterTypeBtnName to the next item in array
      this.commandsFilter.filterTypeBtnName = this.commandsTypes[index + 1];
    },

    /**
     * Clear filter text.
     */
    clearFilter: function () {
      // Clear filter text
      this.commandsFilter.filter = "";
    },

    /**
     * Check if command is included in the filter by command name and processIn.
     *
     * @param {Object} cmd - Command object from the server.
     * @returns {Boolean} - True if command is included in the filter, false otherwise.
     */
    checkFilters: function (cmd) {
      const filter = this.commandsFilter.filter.toLowerCase();
      const filterType = this.commandsFilter.filterTypeBtnName;

      // Check if filter type is All or if command processIn includes filter type
      const typeMatch =
        filterType === "All" || cmd.processIn.includes(filterType);

      // Check if command name includes filter
      const nameMatch = cmd.command.toLowerCase().includes(filter);

      // Return true if all conditions are met
      return typeMatch && nameMatch;
    },

    /**
     * Collect all processIn types from commands and store them in
     * this.commandsTypes.
     */
    collectProcessIn: function () {
      // Initialize commandsTypes array with All
      this.commandsTypes = ["All"];

      // Loop through all commands
      for (let i = 0; i < this.commands.length; i++) {
        // Get processIn string from current command
        const processIn = this.commands[i].processIn;

        // Split processIn string into array
        const processInArray = processIn.split(",");

        // Loop through each item in processIn array
        for (let j = 0; j < processInArray.length; j++) {
          // Trim whitespace from current item
          const item = processInArray[j].trim();

          // Check if current item is not in commandsTypes array
          if (this.commandsTypes.indexOf(item) == -1) {
            // Add current item to commandsTypes array
            this.commandsTypes.push(item);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.descr {
  font-weight: bold;
}

.params,
.return,
.processIn,
.request,
.response {
  font-weight: lighter;
}
</style>
