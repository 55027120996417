<template>
  <!-- List of action groups -->
  <div>
    <!-- Title -->
    <h1 class="mb-0">Action groups</h1>
    <div class="text-secondary mb-3">
      <small>This is a list of action groups.</small>
    </div>

    <!-- List of actions -->
    <div v-for="(action, index) in actions" :key="index">
      <hr />
      <ActionGroup :action="action" class="mb-4" />
    </div>

    <!-- Next page button -->
    <div v-if="previous && !error" class="text-center">
      <hr />
      <a
        class="btn btn-secondary"
        :class="processGetPage ? 'disabled' : ''"
        @click="getNextPage()"
      >
        <i v-if="!processGetPage" class="bi bi-box-arrow-down"></i>
        <i v-else class="bi bi-arrow-clockwise"></i>
      </a>
    </div>
  </div>
</template>

<script>
import ActionGroup from "./ActionGroup.vue";

const cmdJournalList = "journal.list/";

export default {
  name: "ActionGroups",

  components: {
    ActionGroup,
  },

  data() {
    return {
      actions: [],
      processGetPage: false,
      previous: "",
      error: "",
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      switch (gw.command) {
        case cmdJournalList + that.previous: {
          that.processGetPage = false;

          if (gw.err) {
            that.error = "Error: " + gw.err;
            return;
          }

          let actions = JSON.parse(data);
          that.previous = actions.pagination;
          if (actions.rows) {
            that.actions = that.actions.concat(actions.rows);
          } else {
            that.previous = "";
          }
          console.debug("got journal list", actions);
          break;
        }
      }
    });

    // Check list scroll to bottom
    window.addEventListener("scroll", this.scrollListener);

    // Send command when login
    this.whenLogin.then(this.getNextPage);
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
    window.removeEventListener("scroll", this.scrollListener);
  },

  methods: {
    scrollListener() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight + 300 >= scrollHeight) {
        if (this.previous && !this.error && !this.processGetPage) {
          this.getNextPage();
        }
      }
    },

    getNextPage() {
      this.processGetPage = true;
      this.teoweb.sendCmd(cmdJournalList + this.previous);
    },
  },
};
</script>
