<!-- Fraction quests page -->
<template>
  <h1>Fraction quests</h1>

  <!-- Select game -->
  <div class="mt-4 mb-4">
    <!-- Select game description -->
    <div class="form-text">1. Select a game to calculate fraction quests.</div>

    <!-- Select game component -->
    <div class="row mt-3">
      <div class="col mt-2">Select game:</div>
      <DadminGames
        class="col text-end"
        :selectgame="game"
        :disabled="calculating"
        @game="
          (game) => {
            this.game = game;
            setGame(game);
          }
        "
      />
    </div>
  </div>

  <!-- Calculate fractions -->
  <div v-if="!allGames()" class="mb-4">
    <!-- Calculate fractions description -->
    <div class="form-text">
      2. Press the button below to calculate fractions quests{{
        !allGames() ? " in game " + game : ""
      }}.
    </div>

    <!-- Calculate fractions component -->
    <FractionCalculate
      :disabled="calculatingDisabled"
      class="mt-2"
      :game="game"
      @states="calculateStates"
    />
  </div>

  <!-- Wait calculating result -->
  <div v-if="calculating || result" class="mb-4">
    <!-- Wait calculating result description -->
    <div class="form-text">
      3. Wait until the result is calculated or press stop button above.
    </div>
    <pre
      v-if="result"
      class="mt-2 mb-0 border rounded p-3"
    ><code>{{ result.result }}</code></pre>
  </div>

  <!-- Aprove action -->
  <div v-if="result" class="mb-4">
    <!-- Aprove result description -->
    <div class="form-text">
      4. Calculated totalCount will be set to all quests. To do this press
      Confirm button.
    </div>

    <!-- Execute fractions component -->
    <FractionExecute :game="game" :result="result" @states="calculateStates" />

    <!-- All done -->
    <div v-if="executed">
      <div class="alert alert-success mt-3">
        All done. You can now close this window.
      </div>
      <button class="btn btn-secondary" @click="calculateStates('stop')">
        Close
      </button>
    </div>
  </div>

  <!-- Error message -->
  <div v-if="error" class="alert alert-danger mt-3" role="alert">
    {{ error }}
  </div>
</template>

<script>
import FractionCalculate from "../FractionCalculate.vue";
import FractionExecute from "../FractionExecute.vue";
import DadminGames from "../DadminGames.vue";

const allGames = "All games";
const fraction = "fraction";

export default {
  name: "FractionQuests",

  components: {
    FractionCalculate,
    FractionExecute,
    DadminGames,
  },

  data() {
    return {
      game: this.getGame(),
      calculatingDisabled: false,
      calculating: false,
      executed: false,
      result: null,
      error: "",
    };
  },

  methods: {
    calculateStates(state, val) {
      console.debug("calculate state:", state);
      switch (state) {
        // Calculating started
        case "start":
          this.calculatingDisabled = true;
          this.calculating = true;
          this.executed = false;
          this.result = null;
          this.error = "";
          break;

        // Calculating stopped by stop button
        case "stop":
          this.calculatingDisabled = false;
          this.calculating = false;
          this.executed = false;
          this.result = null;
          this.error = "";
          break;

        // Calculating done result
        case "result":
          this.result = JSON.parse(val);
          console.debug("result:", this.result);
          break;

        // Executing done
        case "executed":
          console.debug("executed", val);
          this.executed = true;
          break;

        // Error
        case "error":
          this.error = val;
          break;
      }
    },

    /**
     * allGames - Returns true if the current game is "All games".
     * @return {boolean} - True if the current game is "All games".
     */
    allGames() {
      return this.game == "" || this.game == allGames;
    },

    /**
     * Saves the selected game to local storage.
     * @param {string} game - The name of the game to save.
     */
    setGame(game) {
      // Get the current fraction quests from local storage
      const fractionObj = JSON.parse(localStorage.getItem(fraction) || "{}");

      // Set the game in the fraction quests
      fractionObj.game = game;

      // Save the fraction quests back to local storage
      localStorage.setItem(fraction, JSON.stringify(fractionObj));
    },

    /**
     * Returns the currently selected game from local storage.
     * @return {string} - The name of the currently selected game.
     */
    getGame() {
      // Return the game from the fraction quests, or "" if not found
      return JSON.parse(localStorage.getItem(fraction) || "{}").game || "";
    },
  },
};
</script>
