<template>
  <div>
    <!-- Button -->
    <button
      v-if="btn"
      class="btn btn-outline-secondary"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span>{{ selectedEntry }}</span>
      <i class="bi bi-clock-history"></i>
    </button>

    <!-- Icon -->
    <div
      v-else
      class="addon addon-right"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="bi bi-clock-history"></i>
    </div>

    <!-- List of history items -->
    <ul class="card dropdown-menu">
      <!-- Header -->
      <div class="card-header row">
        <!-- Title -->
        <h3 class="col mb-0 p-0 ps-2 dropdown-item-text">Filter history</h3>
        <!-- Close button -->
        <div class="col p-1 text-end">
          <button type="button" class="btn-close" aria-label="Close"></button>
        </div>
      </div>

      <!-- History items -->
      <li v-for="(entry, idx) in history" :key="idx">
        <hr v-if="idx > 0" class="dropdown-divider" />
        <div v-else class="mb-2"></div>
        <a
          class="dropdown-item"
          @click="
            selectedEntry;
            $emit('selectedEntry', entry);
          "
        >
          {{ entry }}<br />
          <button class="btn btn-del" @click="del(idx)">
            <i class="bi bi-trash"></i>
          </button>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
const prefix = "historyFilter-";

export default {
  name: "FilterHistory",

  emits: ["selectedEntry"],

  props: {
    btn: Boolean,
    name: String,
    value: String,
  },

  data() {
    return {
      history: [],
      selectedEntry: "",
    };
  },

  mounted() {
    this.get();
    this.add(this.value);
  },

  methods: {
    // Method add adds filter to history and localstorage
    add(str) {
      if (!str) {
        return;
      }

      const idx = this.history.indexOf(str);
      this.del(idx);
      this.history.unshift(str);
      this.set();
    },

    // Method get reads history from localstorage
    get() {
      const filterStr = localStorage.getItem(prefix + this.name);
      this.history = [];
      if (filterStr) {
        this.history = JSON.parse(filterStr);
      }
    },

    // Method del deletes filter from history and localstorage
    del(idx) {
      if (idx >= 0) {
        this.history.splice(idx, 1);
        this.set();
      }
    },

    // Method set saves history to storage
    set() {
      localStorage.setItem(prefix + this.name, JSON.stringify(this.history));
    },
  },

  watch: {
    value: function () {
      this.add(this.value);
    },
  },
};
</script>

<style scoped>
.action-list button {
  width: 45px;
  height: 100%;
  border-radius: 0;
  min-height: 43px !important;
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: var(--bs-body-bg);
}

.btn {
  border: none;
  padding: 0;
  opacity: 0.5;
}

.btn:hover {
  opacity: 1;
}

.addon-right {
  right: 75px;
}

.card.dropdown-menu {
  display: none;
}
.card.dropdown-menu.show {
  display: block;
}

.dropdown-menu {
  padding-top: 0;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-item {
  text-wrap: auto;
}

.card-header {
  position: sticky;
  top: 0;
  z-index: 1020;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}
</style>
