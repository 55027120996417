import { createApp } from 'vue'
import App from './App.vue'

import teoweb from 'teoweb' // to use teonet webrtc connection
import 'bootstrap/dist/css/bootstrap.css' // to use bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.js' // to use bootstrap JS
import 'bootstrap-icons/font/bootstrap-icons.css'; // to use bootstrap icons
import './bootstrap_theme_tolge.js' // to use bootstrap color theme tolge

let server_name = "dadmin-server";
try {
    let s = require('./server_name.js')
    // 
    console.debug("got server_name from file:", s.server_name)
    server_name = s.server_name
} catch (error) {
    //
    console.debug("got server_name default:", server_name)
}

const app = createApp(App)
app.config.globalProperties.teoweb = teoweb();
app.config.globalProperties.server_name = server_name;

// whenLogin promise waits when frontend connected to webrtc server and login 
// token was checked.
app.config.globalProperties.whenLogin = new Promise((resolve, reject) => {
    const teoweb = app.config.globalProperties.teoweb;
    const started = performance.now();
    const timeout = 0;

    const check = () => {
        if (teoweb.token != null) {
            resolve();
            return;
        }
        if (timeout && performance.now() - started >= timeout) {
            reject();
            return;
        }
        requestAnimationFrame(check);
    }
    check();
});

// webasmReady promise waits for webasm to be ready.
app.config.globalProperties.webasmReady = new Promise((resolve, reject) => {
    const started = performance.now();
    const timeout = 0;

    const check = () => {
        if (window.decompressData) {
            resolve();
            return;
        }
        if (timeout && performance.now() - started >= timeout) {
            reject();
            return;
        }
        requestAnimationFrame(check);
    }
    check();
});

// sendCmd promise send teoweb command and waits answer from server or timeout.
app.config.globalProperties.sendCmd = function (cmd, data = null, timeout = 10000) {
    const teoweb = app.config.globalProperties.teoweb;
    const whenLogin = app.config.globalProperties.whenLogin;

    // The proccessed flag will be set to true when teoweb answer will be 
    // received
    let processed = false;
    let error = null;

    // Create and add reader which will receive data from WebRTC Data Channel
    const reader = teoweb.addReader(function (gw, d) {
        if (gw.command == cmd) {
            data = d;
            if (gw.err) {
                error = gw.err;
            }
            processed = true;
        }
    });

    // Delete reader function
    const delReader = () => teoweb.delReader(reader);

    // Return promise which will be resolved when login done or timeout and
    // teoweb answer will be received
    return new Promise((resolve, reject) => {

        const timeout_error = "answer timeout";
        const timeout_login_error = "login timeout";

        // Wait login done
        whenLogin.then(
            // Login success
            () => {
                const started = performance.now();

                // Send request with command and data to WebRTC serve
                teoweb.sendCmd(cmd, data);

                const check = () => {
                    if (processed) {
                        delReader();
                        if (error) {
                            reject(error);
                        } else {
                            resolve(data);
                        }
                        return;
                    }
                    if (timeout && performance.now() - started >= timeout) {
                        delReader();
                        reject(timeout_error);
                        return;
                    }
                    requestAnimationFrame(check);
                }
                check();
            },

            // Login failed
            () => {
                delReader();
                reject(timeout_login_error);
            }
        );
    })
};

app.mount('#app')