<template>
  <!-- Title -->
  <h1 class="mb-0">Inventory</h1>
  <small class="text-secondary"
    >This is a list of available inventory items.</small
  >

  <!-- Filter -->
  <div class="input-group mt-1">
    <!-- Filter text -->
    <input
      class="form-control"
      v-model="itemsFilter.filter"
      placeholder="Enter filter text"
    />

    <!-- Clear filter button -->
    <button
      type="button"
      class="btn bg-transparent"
      @click="clearFilter()"
      style="
        opacity: 0.5;
        margin-left: -33px;
        padding-top: 1px;
        margin-top: 5px;
        margin-bottom: 5px;
        z-index: 100;
      "
    >
      <i class="fa fa-times"></i>x
    </button>

    <!-- Filter type button -->
    <button type="button" class="btn btn-primary" @click="changefilterOnline()">
      {{ itemsFilter.filterTypeBtnName }}
    </button>
  </div>

  <!-- List of items -->
  <div v-for="(item, key, index) in items" :key="key">
    <div v-if="checkFilters(item)">
      <!-- Horizontal separator or empty div depending on index -->
      <hr v-if="index" />
      <div v-else class="mb-4"></div>

      <!-- Item name -->
      <h5>{{ item.Name }}</h5>

      <!-- Item description -->
      <div class="description">
        <div>Type: {{ item.Type }}</div>
        <div>Rarity: {{ item.Rarity }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const cmdItemsList = "items.list";

export default {
  name: "ItemsList",

  data() {
    return {
      items: {},
      itemTypes: ["All"],
      itemsFilter: {
        filter: "",
        filterTypeBtnName: "All",
      },
    };
  },

  mounted() {
    let that = this;

    // Send 'cmdItemsList' command to server
    this.sendCmd(cmdItemsList).then(
      // Process success answer
      (data) => {
        that.webasmReady.then(() => {
          data = window.decompressData(data);
          that.items = JSON.parse(data);
          that.makeTypes(that.items);
        });
      },
      // Process an error
      (err) => (that.error = err)
    );
  },

  methods: {
    changefilterOnline() {
      // Get index of filterTypeBtnName in itemTypes
      const index = this.itemTypes.indexOf(this.itemsFilter.filterTypeBtnName);
      if (index == -1 || index == this.itemTypes.length - 1) {
        this.itemsFilter.filterTypeBtnName = this.itemTypes[0];
        return;
      }

      // Set next item type to filterType button Name
      this.itemsFilter.filterTypeBtnName = this.itemTypes[index + 1];
    },

    clearFilter() {
      this.itemsFilter.filter = "";
    },

    checkFilters(item) {
      // Check if filterBtnName equals to type
      if (
        this.itemsFilter.filterTypeBtnName != "All" &&
        this.itemsFilter.filterTypeBtnName != item.Type
      ) {
        return false;
      }

      // Check if item name contains filter
      if (
        item.Name.toLowerCase().indexOf(
          this.itemsFilter.filter.toLowerCase()
        ) == -1
      ) {
        return false;
      }

      return true;
    },

    makeTypes(items) {
      // Get item types from items
      this.itemTypes = [];
      for (const item of Object.values(items)) {
        const type = item.Type;
        if (this.itemTypes.indexOf(type) == -1) {
          this.itemTypes.push(type);
        }
      }
      this.itemTypes.sort();

      // Add 'All' type
      this.itemTypes.unshift("All");

      // Set first item type to filterType button Name
      this.itemsFilter.filterTypeBtnName = this.itemTypes[0];
    },
  },
};
</script>
