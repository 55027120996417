<template>
  <!-- Copy to clipboard button -->
  <div class="bd-clipboard">
    <button
      type="button"
      class="btn bg-transparent btn-clipboard"
      title=""
      data-bs-original-title="Copy to clipboard"
      @click="copyToClipboard(text)"
    >
      <i class="bi bi-clipboard"></i>
    </button>
  </div>
  <!-- Code text -->
  <pre class="mt-2 mb-0 border rounded"><code>{{ text }}</code></pre>
</template>

<script>
export default {
  name: "CodeCopy",

  props: {
    text: String,
  },

  methods: {
    /**
     * Copy code to clipboard
     *
     * @param {string} text text to copy
     */
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        // coppied
      } catch ($e) {
        // error
      }
    },
  },
};
</script>

<style scoped>
.bd-clipboard {
  position: relative;
  display: block;
  float: right;
}

.btn-clipboard {
  position: absolute;
  top: 1.9rem;
  right: -0.2rem;
  z-index: 10;
  display: block;
  padding: 0.25rem 0.5rem;
}

pre {
  padding: 20px;
  padding-top: 30px;
}
</style>
