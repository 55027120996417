<template>
  <button
    class="btn btn-outline-secondary"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ selectedSign }}
  </button>

  <ul class="dropdown-menu">
    <div v-for="(sign, key) in signs" :key="key">
      <a
        class="dropdown-item"
        @click="
          selectedSign = sign;
          $emit('sign', sign);
        "
        >{{ sign }}</a
      >
    </div>
  </ul>
</template>

<script>
export default {
  name: "SignsDropdown",

  emits: ["sign"],

  data() {
    return {
      signs: ["+=", "-=", "="],
      selectedSign: "+=",
    };
  },

  mounted () {
    this.$emit('sign', this.selectedSign);
  },
};
</script>

<style scoped>
  .btn-outline-secondary {
    border-left: none;
  }
</style>