<template>
  <!-- Filter input and Search button -->
  <div class="input-group mb-3">
    <!-- Dropdown type of filter -->
    <button
      class="btn btn-outline-secondary dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ filter.type }}
    </button>
    <ul class="dropdown-menu">
      <li v-for="(type, index) in filterTypes" :key="index">
        <a
          class="dropdown-item"
          @click="
            filter.type = type;
            sendToParent();
          "
          >{{ type }}</a
        >
      </li>
    </ul>

    <!-- Filter input -->
    <input
      type="text"
      class="form-control"
      placeholder="text of filter"
      v-model="filter[filter.type]"
      @keydown.enter="sendToParent"
    />
    <!-- Add to dynamically refresh list when typing filter -->
    <!-- v-on:input="sendToParent()" -->

    <!-- Search button -->
    <a class="btn btn-secondary" @click="sendToParent()"
      ><i class="bi bi-search"></i
    ></a>
  </div>
</template>

<script>
const usersFilterName = "usersFilter";

export default {
  name: "UsersFilter",

  props: {
    type: String,
    name: String,
  },

  data() {
    return {
      usersList: [],
      previous: "",
      filter: {
        username: "",
        email: "",
        sub: "",
        type: "username",
      },
      filterTypes: ["username", "email", "sub"],
    };
  },

  emmits: ["filter"],

  mounted() {
    this.getFilter();
    this.whenLogin.then(this.sendToParent);
  },

  methods: {
    /**
     * sendToParent - Return event filter with filter type and filter name.
     */
    sendToParent() {
      const type = this.filter.type;
      const filter = type + '^="' + this.filter[type] + '"';
      this.setFilter();
      this.$emit("filter", filter);
    },

    // Load filter set from local storage
    getFilter() {
      let usersFilter = localStorage.getItem(usersFilterName);
      if (usersFilter) {
        let filter = JSON.parse(usersFilter);
        if (filter.allgames) {
          this.filter = filter.allgames;
        }
      }
    },

    // Save filter set to local storage
    setFilter() {
      let filter = {};
      let usersFilter = localStorage.getItem(usersFilterName);
      if (usersFilter) {
        // If usersFilter is set, parse it and set the game.
        filter = JSON.parse(usersFilter);
      } else {
        // If usersFilter is not set, create a new object.
        filter = {};
      }
      filter.allgames = this.filter;
      localStorage.setItem(usersFilterName, JSON.stringify(filter));
    },
  },
};
</script>
