<template>
  <!-- Confirm -->
  <ActionConfirm
    class="mt-2"
    :action="actions[confirm]"
    @confirm="doConfirm"
    @cancel="doCancel"
  />

  <!-- Loading indicator -->
  <div v-if="executing" class="d-flex justify-content-center m-4">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import ActionConfirm from "./ActionConfirm.vue";

export default {
  name: "FractionExecute",

  components: {
    ActionConfirm,
  },

  props: {
    game: String,
    result: Object,
  },

  emits: ["states"],

  data() {
    return {
      executing: false,
      confirm: "approve",
      actions: {
        approve: {
          name: "approve",
          text: "Approve and Perform this action?",
          // word: "approve",
        },
      },
    };
  },

  methods: {
    doConfirm() {
      this.executing = true;
      this.confirm = "";
      this.sendCmd(
        "fraction.execute/" + this.game,
        this.result.totalQuest,
        0
      ).then(
        (res) => {
          // Send state "executed" to parent
          this.$emit("states", "executed", res);
          this.executing = false;
        },
        (err) => {
          // Send state "error" to parent
          this.$emit("states", "error", err);
          this.executing = false;
        }
      );
    },

    doCancel() {
      this.$emit("states", "stop");
    },
  },
};
</script>
