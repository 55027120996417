<template>
  <!-- Action group view -->
  <div class="card-body mb-2">
    <!-- Title -->
    <div class="row">
      <div
        class="mb-1 col"
        :role="!top ? 'button' : ''"
        @click="select(action.ID)"
      >
        <h1 v-if="top" class="mb-2">{{ action.Name || "Action" }}</h1>
        <h5 v-else class="mb-2">{{ action.Name || "Action" }}</h5>
        <div class="description mb-0 text-secondary dropdown-toggle">
          <div>Id: {{ action.ID }}</div>
          <div>Date: {{ formatDate(action.Date) }}</div>
        </div>
      </div>
      <div
        v-if="top"
        class="col nav-link text-end mt-2 me-4"
        type="button"
        @click="select()"
      >
        <i class="bi bi-list-ul"></i>
      </div>
    </div>

    <!-- Description -->
    <div class="description">
      <div>Dadmin user: {{ action.User || "Unknown" }}</div>
      <div>
        Reason:
        {{ action.Reason || "It was urgently necessary" }}
      </div>
      <div>Game: {{ action.Game || "All games" }}</div>
      <div class="pt-1 col-6">
        <div>Filter:</div>
        <div class="rounded">{{ action.Filter || "Not set" }}</div>
      </div>
      <div class="pt-1 pb-1 col-6">
        <div>Actions:</div>
        <div class="">{{ action.ActionArr || "Empty" }}</div>
      </div>
      <div>
        Actions executed:
        {{ action.Actions != "" ? action.Actions : getActionsLength() }}
      </div>
    </div>

    <!-- Approval buttons or status -->
    <div v-if="!error">
      <!-- Buttons -->
      <div v-if="approved == 0 && !confirm" class="form-group">
        <!-- Appoval button -->
        <input
          type="button"
          class="btn btn-success btn-sm"
          value="Approve and Perform"
          @click="doApprove"
        />

        <!-- Reject button -->
        <input
          type="button"
          class="btn btn-danger btn-sm float-end"
          value="Reject this Action"
          @click="doReject"
        />
      </div>

      <!-- Status -->
      <div v-else>
        <div v-if="approved == 1" class="badge bg-success">Performed</div>
        <div v-if="approved == 2" class="badge bg-danger">Rejected</div>
        <div v-if="approved == 3" class="spinner-border text-warning"></div>
      </div>

      <!-- Confirm actions -->
      <ActionConfirm
        class="mt-4"
        :action="actions[confirm]"
        @confirm="doConfirm"
        @cancel="doCancelConfirm"
      />
    </div>
  </div>
</template>

<script>
import ActionConfirm from "./ActionConfirm.vue";

const cmdGroupLength = "journal.list.length/";
const cmdApproveAction = "users.action.approve/";
const cmdJournalList = "journal.list.actions/";

export default {
  name: "ActionGroup",

  components: {
    ActionConfirm,
  },

  props: {
    action: Object,
    error: String,
    top: Boolean,
  },

  data() {
    return {
      approved: 0,

      /** confirm action */
      confirm: "",
      actions: {
        approve: {
          name: "approve",
          text: "Approve and Perform this action?",
          // word: "approve",
        },
        reject: {
          name: "reject",
          text: "Reject this action?",
          // word: "approve",
        },
      },
    };
  },

  mounted() {
    let that = this;

    // Set approved status from prop to data variable
    this.approved = this.action.Approved;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }

      // Process answer to cmdApproveAction commands
      if (gw.command.startsWith(cmdApproveAction + that.action.ID)) {
        // Get second parameter from gw.command
        let approved = JSON.parse(gw.command.split("/")[2]);
        if (approved == true) {
          // Set approved status to performed
          that.approved = 1;
        } else {
          // Set approved status to rejected
          that.approved = 2;
        }

        // Reqest list of actions
        that.teoweb.sendCmd(cmdJournalList + "/" + that.action.ID);
      }

      // Process answer to commands
      switch (gw.command) {
        case cmdGroupLength + that.action.ID:
          that.action.Actions = JSON.parse(data);
          break;
      }
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    select: function (id) {
      if (id) {
        window.location.href = "#/action_group/" + id;
        return;
      }
      window.location.href = "#/action_groups";
    },

    /** Request number of actions in action group */
    getActionsLength() {
      this.teoweb.sendCmd(cmdGroupLength + this.action.ID);
    },

    doApprove() {
      this.confirm = "approve";
    },

    doReject() {
      this.confirm = "reject";
    },

    doConfirm() {
      let approve = false;
      if (this.confirm == "approve") {
        approve = true;
      }

      // Send approve command with second parameter true
      this.teoweb.sendCmd(cmdApproveAction + this.action.ID + "/" + approve);

      // Set approved status to pending
      this.approved = 3;

      // Clear confirm word
      this.confirm = "";
    },

    doCancelConfirm() {
      // Clear confirm word
      this.confirm = "";
    },
  },

  watch: {
    // Set approved when action property changes
    action: function () {
      this.approved = this.action.Approved;
    },
  },
};
</script>
