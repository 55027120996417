<template>
  <!-- Confirm action -->
  <div v-if="action" class="form-group">
    <div class="">
      <!-- Title -->
      <div class="mb-3">{{ action.text }}</div>

      <!-- Fields -->
      <div v-for="(field, index) in fields" :key="index">
        <label class="form-label description">{{ field }}:</label>
        <input
          :placeholder="field"
          class="form-control mb-3"
          v-model="fieldsArr[index]"
        />
      </div>

      <!-- Confirm word -->
      <div v-if="action.word">
        <label class="form-label description"
          >To confirm this action, enter the text '{{ action.word }}' and click
          the Confirm button:
        </label>
        <input
          class="form-control mb-1"
          v-model="confirmWord"
          :placeholder="action.word"
          type="text"
        />
      </div>

      <!-- No confirm word text -->
      <div v-else class="description">Please confirm your action:</div>
    </div>

    <!-- Confirm button -->
    <input
      type="button"
      class="btn btn-danger btn-sm mt-2 mr-2"
      value="Confirm"
      :disabled="checkFields()"
      @click="doConfirm"
    />

    <!-- Cancel button -->
    <input
      type="button"
      class="btn btn-secondary btn-sm mt-2"
      value="Cancel"
      @click="doCancel"
    />
  </div>
</template>

<script>
export default {
  name: "ActionConfirm",

  props: {
    action: Object,
    fields: Array,
  },

  data: function () {
    return {
      confirmWord: "",
      fieldsArr: this.newFieldsArr(),
    };
  },

  methods: {

    /**
     * newFieldsArr returns an array of empty strings with the same length as
     * the fields prop.
     *
     * @return {Array<string>} An array of empty strings.
     */
    newFieldsArr() {
      if (this.fields) {
        return new Array(this.fields.length).fill("");
      }
      return [];
    },

    /**
     * Confirm action.
     *
     * Emits 'confirm' event with two parameters: action name and array of
     * strings containing values of fields.
     */
    doConfirm() {
      this.$emit("confirm", this.action.name, this.fieldsArr);
      this.fieldsArr = this.newFieldsArr();
    },

    /**
     * Cancel action.
     *
     * Emits 'cancel' event and clears input fields.
     */
    doCancel() {
      this.$emit("cancel");
      this.fieldsArr = this.newFieldsArr();
    },

    /**
     * Checks if the action word is correct and at least one field is empty string.
     *
     * @return {boolean} True if action word is incorrect or at least one field is
     * empty string.
     */
    checkFields() {
      // Check if action word is correct
      if (this.action.word && this.action.word !== this.confirmWord) {
        return true; // Action word is incorrect
      }

      // Check if at least one field is empty
      let result = false;
      this.fieldsArr.forEach((field) => {
        if (!field || field.length < 3) {
          result = true; // At least one field is empty
        }
      });
      return result;
    },
  },

  watch: {
    // Clear confirm word when action changes to empty string (closed).
    action: function (value) {
      if (!value) {
        this.confirmWord = "";
      }
    },
  },
};
</script>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}
</style>
