<template>
  <div>
    <!-- Button -->
    <button
      :class="btn ? 'btn btn-outline-secondary' : ''"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span>{{ selectedItem }}</span>
      <i class="bi bi-collection"></i>
    </button>

    <!-- List of items -->
    <ul class="dropdown-menu">
      <input
        type="text"
        class="form-control"
        placeholder="start typing itemId..."
        v-model="filter"
      />

      <li v-for="(item, key) in items" :key="key">
        <a
          v-if="checkFilters(item)"
          class="dropdown-item"
          @click="
            selectedItem = item.Name;
            $emit('itemId', item.Name);
          "
          >{{ item.Name }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
const cmdItemsList = "items.list";

export default {
  name: "InventoryListDropdown",

  props: {
    btn: Boolean,
  },

  emits: ["itemId"],

  data() {
    return {
      items: {},
      selectedItem: "ItemId",
      filter: "",
    };
  },

  mounted() {
    let that = this;

    // Send 'cmdItemsList' command to Teonet
    this.sendCmd(cmdItemsList).then(
      // Process success answer
      (data) => {
        that.webasmReady.then(() => {
          data = window.decompressData(data);
          that.items = JSON.parse(data);
        });
      },
      // Process an error
      (err) => (that.error = err)
    );
  },

  methods: {
    checkFilters(item) {
      // Check if item name contains filter
      if (item.Name.toLowerCase().indexOf(this.filter.toLowerCase()) == -1) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
/* Dropdown styles */
.form-control {
  position: sticky;
  top: 0;
}
.dropdown-menu {
  padding-top: 0%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.btn-outline-secondary {
  border-right: none;
  border-radius: 0;
}

/* Action list styles */
.action-list button {
  width: 45px;
  height: 100%;
  border-radius: 0;
  min-height: 43px !important;
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: var(--bs-body-bg);
}
.action-list button span {
  display: none;
}
:not(.action-list) button i {
  display: none;
}
.action-list button i {
  margin: auto;
  display: flex;
}

/* Journal styles */
.journal button {
  border: 0;
  padding: 0;
  margin: 5px;
  opacity: 0.5;
  width: unset;
  min-height: unset !important;
  display: flex;
  height: 25px;
}
.action-list button i {
  margin: 0;
}
.journal button:hover {
  opacity: 1;
}
</style>
