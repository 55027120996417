<!-- List of Teonet monitors applications. -->
<template>
  <div>
    <!-- <div class="align-items-center"> -->
    <h1>
      Applications
      <div class="badge rounded-pill bg-secondary">{{ peers.length }}</div>
    </h1>
    <!-- </div> -->

    <!-- List of Teonet application processed by monitor. -->
    <!-- filter: -->
    <div class="input-group">
      <input
        class="form-control"
        v-model="appFilter.filter"
        placeholder="Enter filter text"
      />
      <button
        type="button"
        class="btn bg-transparent"
        @click="clearFilters()"
        style="
          opacity: 0.5;
          margin-left: -33px;
          padding-top: 1px;
          margin-top: 5px;
          margin-bottom: 5px;
          z-index: 100;
        "
      >
        <i class="fa fa-times"></i>x
      </button>

      <button
        type="button"
        class="btn btn-primary"
        @click="changefilterOnline()"
      >
        {{ appFilter.filterOnlineBtnName }}
      </button>
    </div>
    <div v-for="index in peers.length" :key="index">
      <div v-if="checkFilters(peers[index - 1])">
        <hr />
        <MonitorApp :app="peers[index - 1]" />
      </div>
    </div>
  </div>
</template>

<script>
import MonitorApp from "./MonitorApp.vue";

const cmdList = "m.list";

export default {
  name: "MonitorApps",
  components: {
    MonitorApp,
  },

  data() {
    return {
      peers: [],
      appFilter: {
        filter: "",
        filterOnline: 0,
        filterOnlineBtnName: "All",
      },
    };
  },

  mounted() {
    let that = this;

    let filter = localStorage.getItem("appFilter");
    if (filter) {
      this.appFilter = JSON.parse(localStorage.getItem("appFilter"));
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdList:
          that.peers = JSON.parse(data);
          break;
      }
    });

    // Send 'm.list' command to WebRTC server every 10 seccont after connected
    // this.teoweb.sendCmd(cmdList);
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdList);
      that.intervalId = setInterval(function () {
        that.teoweb.sendCmd(cmdList);
      }, 10000);
    });
  },

  unmounted: function () {
    clearInterval(this.intervalId);
    this.teoweb.delReader(this.reader);

    // Write filter settings
    localStorage.setItem("appFilter", JSON.stringify(this.appFilter));
  },

  methods: {
    /** Change state filter online value and buttons name */
    changefilterOnline() {
      this.appFilter.filterOnline =
        this.appFilter.filterOnline < 2 ? ++this.appFilter.filterOnline : 0;
      switch (this.appFilter.filterOnline) {
        case 0:
          this.appFilter.filterOnlineBtnName = "All";
          break;
        case 1:
          this.appFilter.filterOnlineBtnName = "Online";
          break;
        case 2:
          this.appFilter.filterOnlineBtnName = "Offline";
          break;
      }
    },

    /** clear all filters information */
    clearFilters() {
      this.appFilter.filter = "";
      this.appFilter.filterOnline = 0;
      this.appFilter.filterOnlineBtnName = "All";
    },

    checkFilters(peer) {
      // Check online filter
      if (this.appFilter.filterOnline == 1 && !peer.Online) return false;
      if (this.appFilter.filterOnline == 2 && peer.Online) return false;

      // Find filter text in peers fields
      if (this.appFilter.filter) {
        let filter = this.appFilter.filter.toLowerCase().trim();
        const words = filter.split(" ");
        for (const filter of words) {
          if (
            peer.AppShort.toLowerCase().indexOf(filter) == -1 &&
            peer.AppName.toLowerCase().indexOf(filter) == -1 &&
            peer.AppVersion.toLowerCase().indexOf(filter) == -1 &&
            peer.TeoVersion.toLowerCase().indexOf(filter) == -1 &&
            (!peer.Host || peer.Host.toLowerCase().indexOf(filter) == -1)
          )
            return false;
        }
      }

      return true;
    },
  },
};
</script>

<style scoped>
div.badge {
  font-size: 10px;
}
</style>
