<!-- Fraction quest calculate component -->
<template>
  <div>
    <!-- Button calculate -->
    <button
      class="btn btn-primary"
      :disabled="allGames() || calculating || disabled"
      @click="calculate('start')"
    >
      <span
        v-if="calculating"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span v-if="calculating"> Calculating...</span>
      <span v-else>Calculate fraction quests</span>
    </button>

    <!-- Button stop calculation -->
    <button
      class="btn btn-danger ms-2"
      :hidden="!calculating"
      @click="calculate('stop')"
    >
      Stop
    </button>
  </div>
</template>

<script>
const allGames = "All games";

export default {
  name: "FractionCalculate",

  props: {
    game: String,
    disabled: Boolean,
  },

  emits: ["states"],

  data() {
    return {
      calculating: false,
    };
  },

  methods: {
    calculate(state) {
      switch (state) {
        case "start":
          this.calculating = true;
          this.sendCmdCalculate(this.game);
          this.$emit("states", state, this.game);
          break;
        case "stop":
          this.calculating = false;
          this.$emit("states", state, this.game);
          break;
      }
    },

    sendCmdCalculate(game) {
      // Send "fraction.calculate" command to Teonet
      this.sendCmd("fraction.calculate/" + game, null, 0).then(
        // Process success answer
        (data) => {
          // Decompress calculation result.
          const result = window.decompressData(data);
          console.debug("got calculate result length:", result.length);

          // Send state "result" to parent
          this.$emit("states", "result", result);
          this.calculating = false;
        },
        // Process an error. Send state "error" to parent
        (err) => this.$emit("states", "error", err)
      );
    },

    /**
     * allGames - Returns true if the current game is "All games".
     * @return {boolean} - True if the current game is "All games".
     */
    allGames() {
      return this.game == "" || this.game == allGames;
    },
  },
};
</script>
