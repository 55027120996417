<template>
  <!-- Help button -->
  <div
    class="collapsed"
    data-bs-toggle="collapse"
    data-bs-target="#collapseHelp"
    aria-expanded="false"
    aria-controls="collapseHelp"
  >
    <i class="bi bi-question-lg"></i>

    <!-- Help subject -->
    <div class="form-text">
      Filter journal entries by date, username, or itemId. Use Calendar and
      Inventory icons to insert their values into the filter.
    </div>
  </div>

  <!-- Help collapse content -->
  <div class="collapse" :class="show ? 'show' : ''" id="collapseHelp">
    <div class="card">
      <!-- Header -->
      <div class="card-header">
        <div class="row">
          <!-- Title -->
          <h3 class="col mb-0">Filter help</h3>

          <!-- Close button -->
          <div class="col p-1 text-end">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              data-bs-toggle="collapse"
              data-bs-target="#collapseHelp"
            ></button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h5>Find journal entries by Date, Username or itemId:</h5>
        <!-- Example 1 -->
        <div class="mb-3 mt-3">
          Select date, username and item from dropdowns to combine several
          conditions:<br />
          <span class="text-success p-2"
            >date > "2024-10-02" and username = "memoire" and action LIKE
            "%Bolts%"</span
          >
        </div>

        <!-- Explain -->
        <div class="mb-3 mt-3">
          You can also search separately by Date, Username or itemId, just
          select it from definite dropdown and click search button:<br />
        </div>

        <!-- Example 1 -->
        <div class="mb-3 mt-3">
          Example with date<br />
          <div class="text-success p-2">date > "2024-11-01"<br /></div>
        </div>

        <!-- Example 2 -->
        <div class="mb-3 mt-3">
          Example with username<br />
          <div class="text-success p-2">username = "memoire"<br /></div>
        </div>

        <!-- Example 3 -->
        <div class="mb-3 mt-3">
          Example with itemId<br />
          <div class="text-success p-2">action LIKE "%Bolts%"<br /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterHistoryHelp",

  data() {
    return {
      /**
       * The flag to show the form.
       * @type {boolean}
       */
      show: false,
    };
  },
};
</script>

<style scoped>
i {
  opacity: 0.5;
}
i:hover {
  opacity: 1;
}
.collapse {
  margin-top: 30px;
  margin-left: -68px;
}
.form-text {
  margin-top: 5px;
  margin-left: -50px;
  display: none;
  opacity: 0.95;
}
.collapsed .form-text {
  display: block !important;
}
</style>
